.sign-in-opt-box {
  margin-top: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;
}
.sign-in-continue {
  position: relative;
  width: 100%;
}
.sign-in-line-box {
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
  width: 100%;
}
.sign-in-line {
  width: 100%;
  border-top: 1px solid #eee;
  /* z-index: 50; */
}
.sign-in-text-box {
  position: relative;
  display: flex;
  justify-content: center;
  color: #3a3a3a;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}
.sign-in-text {
  background: white;
  padding: 0 40px;
}
.sign-in-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}
.sign-in-btn-icon {
  width: 24px;
  height: 24px;
}

.facebook-button {
  width: 100%;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;

  /* text-align: left; */
  color: #111a24 !important;
}

.input-box {
  /* border: 1px solid black; */
  position: relative;
  width: 100%;
}

.input-switch {
  color: #777777;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  background: transparent;
  marging-bottom: 2px;
  /* width: 100%; */
  /* border: 1px solid black; */
  position: absolute;
  top: 3px;
  right: 6px;
  z-index: 50;
  cursor: pointer;
}
.input-switch-box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  margin-top: -24px;
  /* border: 1px solid black; */
}

