@media screen and (max-width: 1680px) {}

@media screen and (max-width: 1366px) {
    .contactUs-wrapper .left-div {

        max-width: calc(100% - 420px);
    }

    .contactUs-wrapper .right-div {
        max-width: 420px;
    }

    .contactUs-sec .contactUs-wrapper {
        column-gap: 42px;
    }
}

@media screen and (max-width: 1299px) {


    .site-height-wrap.for-student .sidebar {
        min-width: 224px !important;
    }

    .outlet {
        padding: 0 0 0 224px !important;
    }

    .home .home-left {
        max-width: unset;
        width: calc(100% - 280px) !important;
        padding: 24px 20px;
    }

    .std-body-without-right-panel {
        padding: 24px 20px;
    }

    .home-right {
        padding: 16px 15px;
        width: 280px !important;
    }

    .site-height-wrap.for-student .sidebar .sideitem a {
        padding: 8px 12px;
    }

    .site-height-wrap.for-student .sidebar .sideitem a p {
        font-size: 15px;
        padding-top: 4px;
    }

    .contactUs-wrapper .left-div .ant-card-body {
        padding: 20px 20px;
    }

    .site-height-wrap.for-student .sideitem a .manu-icon img {
        max-width: 19px;
    }

    .site-height-wrap.for-student .header .user-info-wrap {
        width: 254px;
    }

    .profile-img {
        width: 32px;
        height: 32px;
    }

    .user-info-wrap .user-info h3 {
        font-size: 14px;
        line-height: 19px;
    }

    .site-height-wrap.for-student .header .center-search-box {
        max-width: 40vw;
    }

    .recommend-exam .recommend-image {
        width: 150px;
    }

    .home .home-left .welcome-banner {
        background-size: 150px;
        margin-bottom: 24px;
        padding: 16px 146px 24px 16px;
        background-position: bottom right 10px;
    }

    .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 14px;
        line-height: 22px;
    }

    .ant-tabs-tab {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .site-height-wrap.for-student .sidebar {
        row-gap: 0;
    }

    .pro-gen-settings .submit-actions .save-btn {
        font-size: 14px;
        width: 220px;
        height: 42px !important;
    }

    .notificationboxwrap {
        padding: 0 16px 0 0;
    }

    .home .home-left .welcome-banner .text-container h3 {
        font-size: 20px;
    }

    .home .home-left .welcome-banner .text-container .text-with-hand-icon .icon-container img {
        width: 22px;
    }

    .home .home-left .welcome-banner .text-container .text-with-hand-icon {
        column-gap: 8px;
    }

    .home .home-left .welcome-banner .text-container p {
        font-size: 14px;
        line-height: 23px;
        margin: 8px 0 0;
    }

    .exam-lists {
        margin-bottom: 16px;
        row-gap: 20px;
    }

    .home .home-right h1,
    .bar-chart-box h3 {
        margin-bottom: 12px;
        font-size: 16px;
    }

    .bar-chart-box {
        padding: 16px 0 0;
    }

    .home .home-right .motivation {
        padding: 30px 10px 15px 10px;
    }

    .home .home-right .average_score,
    .home .home-right .registered_exam,
    .home .home-right .years_completed {
        margin-bottom: 10px;
        padding: 10px;
    }
}

@media screen and (max-width: 1199px) {

    .home-left .exam {
        flex-wrap: wrap;
        background: #fff;
        border: 1px solid #C8CDD6;
        border-radius: 3px;
        padding: 24px 24px 20px 18px;
        row-gap: 16px;
    }

    .home .home-left .exam .examinfo {
        width: 100%;
        align-items: flex-start;
    }

    .home .home-left .exam-lists .exam-list-col .exam .examinfo .image {
        flex: 0 0 auto;
    }

    .home .home-left .exam .exambtnpanel .start-buy {
        margin: 0;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
        padding: 12px 10px !important;

    }

    .home .home-left .exam .exambtnpanel .start-buy p {
        font-size: 17.262px !important;
        font-weight: 500 !important;
        width: unset !important;
    }

    .home .home-left .exam .exambtnpanel {
        width: calc(100% - 165px);
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        padding-left: 28px;
    }

    .buy {
        flex-direction: row;
        align-items: center;
        height: auto;
        width: auto;
        padding: 10px 15px;
    }

    .buy {
        border-radius: 3px;
    }

    .buy p {
        margin-top: 0;
        margin-left: 10px;
    }


    .home .home-left .exam .exam-name h3 {
        margin-bottom: 10px;
        font-size: 20px;
    }

    .home .home-left .exam .exam-name h2 {
        font-size: 18px;
    }

    .recommend-exam .r-exam-name p {
        margin: 0;
    }

    .recommend-exam .recommend-info {
        flex-wrap: wrap;
        width: calc(100% - 183px);
        margin: 0;
        padding: 0 0 0 15px;
        flex-direction: column;
        align-items: flex-start;
    }

    .recommend-exam .r-exam-name br {
        display: none;
    }

    .recommend-exam .r-exam-name {
        margin: 0 0 10px;
    }

    .subcribe-exam-wrap .recommend-exam.subcribeExamPanel .recommend-exam-wrap {
        width: 100%;
    }

    .subcribe-exam-wrap .recommend-exam {
        flex-wrap: wrap;
    }

    .subcribe-exam-wrap .recommend-exam .info-recommend-rt {
        padding: 15px 0 0;
    }

    .home .home-left .exam .exam-name {
        padding-left: 28px;
        margin: 0;
    }

    .home .home-left .exam-lists .exam-list-col .exam .examinfo .image {
        padding: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .password-settings .w-70 {
        width: 100% !important;
    }

    .outlet .contactUs-sec {
        padding: 0 0px 47px 16px;
    }

    .notificationboxwrap {
        padding: 0;
        height: calc(100vh - 370px);
    }

    .site-height-wrap .real-home {
        min-height: auto;
    }

    .subcribe-exam-wrap .recommend-exam .info-recommend-rt {
        padding: 15px;
    }

    .ambassodorBody .ambsidebar {
        top: 0;
        left: -260px;
        position: fixed;
        height: 100%;
        transition: all 500ms ease-in-out;
        z-index: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .ambsideitem .items .menulabel p {
        font-size: 18px;
    }

    .ambassodorBody.menuOpen .ambsidebar {
        left: 0;
    }

    .ambassodormain {
        width: 100%;
    }

    .menu-toggle {
        display: flex;
    }

    .ambmainwrap {
        padding: 20px 15px 0 15px;
    }

    .commtablearea .ttl h3 {
        font-size: 16px;
    }

    .commtablearea .ttl select {
        height: 30px;
    }

    .ambassodorHeader .rt-side .menu-toggle {
        margin-left: 24px;
    }

    .ambassodorHeader .rt-side .cashOut {
        margin: 0 24px 0 0;
    }

    .settings-wrap {
        padding: 15px;
    }

    .subtitle {
        width: 100%;
    }

    .quiListwrap {
        padding: 0 15px 0px;
    }

    .outlet {
        width: 100% !important;
        padding: 0 !important;
    }

    .mobile-menu {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 0;
        padding: 10px 15px;
        z-index: 3;
        background: #fff;
        border-top: 1px solid #E5E5E5;
    }

    .mobile-menu ul {
        display: flex;
        justify-content: space-around;
        padding: 0;
        padding: 0;
    }

    .mobile-menu ul li button {
        background: transparent;
        border-radius: 0;
        border: none;
        outline: none;
        box-shadow: none;
    }

    body {
        height: auto !important;
    }

    .student_dashb_header {
        padding: 10px !important;
    }

    .student_dashb_header .logo {
        width: 130px;
    }

    .site-height-wrap.for-student .header .center-search-box {
        max-width: 224px;
    }

    .stickyheadGap {
        padding: 62px 0 0 0;
    }

    /* start: responsive left sidebar */
    .site-height-wrap.for-student .header .center-search-box {
        display: none;
    }

    .site-height-wrap.for-student .header .user-info-wrap {
        width: unset !important;
        position: relative;
    }

    .site-height-wrap.for-student .header .user-info-wrap .tham {
        width: 35px !important;
        height: 35px !important;
        border-radius: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #0077ff;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
    }

    .site-height-wrap.for-student .header .user-info-wrap .tham img {
        margin: 0 !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 50% !important;
        object-fit: cover !important;
    }

    .site-height-wrap.for-student .header .user-info-wrap .user-info {
        display: none !important;
    }

    .header.student_dashb_header {
        justify-content: space-between;
        padding: 14px 20px !important;
        z-index: 100;
    }

    .site-height-wrap.for-student .sidebar {
        transition: .5s;
        transform: translateX(-105%);
        z-index: 100;
    }

    body.active-student_dashb_header {
        overflow: hidden !important;
    }

    body.active-student_dashb_header .site-height-wrap.for-student .sidebar {
        transform: translateX(0);
    }

    .site-height-wrap.for-student .sidebar {
        padding-top: 24px;
    }

    .site-height-wrap.for-student .sidebar {
        width: 100% !important;
        min-width: unset !important;
        max-width: 320px !important;
        margin-top: 3px;
    }

    .site-height-wrap.for-student .sidebar .sideitem a {
        padding: 10px 35px;
    }

    .site-height-wrap.for-student .sideitem a .manu-icon img {
        max-width: 23px;
    }

    body.active-student_dashb_header .student_dashb_header-overlay {
        display: block;
    }

    /* end: responsive left sidebar */
    .ambassodorHeader {
        padding: 10px 15px;
        border-bottom: 1px solid gainsboro;
    }

    .ambassodorHeader .rt-side .cashOut button {
        font-size: 15px;
        padding: 6px 10px;
        width: 150px;
    }

    .ambcardpanel .box {
        padding: 24px 24px;
    }

    .commtablearea .ttl {
        padding: 12px;
    }

    .tablewrap {
        overflow: unset;
    }

    .tablewrap table {
        display: block;
        width: 100%;
    }

    .tablewrap table thead {
        display: none !important;
    }

    .tablewrap table tbody {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .tablewrap table tbody tr {
        display: block;
        box-shadow: 0px 1px 4px 0px silver;
        padding: 10px;
        border-radius: 10px;
    }

    .tablewrap table tbody tr td {
        display: flex;
        padding: 5px 10px !important;
        column-gap: 14px !important;
        color: #4B4B4B !important;
    }

    .tablewrap table tbody tr td::before {
        content: attr(th-name);
        clear: both;
        display: inline-block;
        font-size: 14px;
        color: #333;
        flex: 0 0 auto;
        width: 90px;
    }

    .settings-wrap .for-desktop {
        display: none !important;
    }

    .settings-wrap .settings-row {
        flex-wrap: wrap;
        row-gap: 24px;
    }

    .settings-wrap .settings-row .left-content {
        max-width: unset;
    }

    .settings-wrap .ttl {
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: space-between;
    }

    .settings-wrap .settings-row .right-actions {
        max-width: unset;
        width: unset;
    }
}

@media screen and (max-width: 991px) {
    .home {
        flex-wrap: wrap;
        row-gap: 16px;
    }

    .outlet .contactUs-sec {
        padding: 0 16px 65px;
    }

    .outlet .contactUs-wrapper .left-div {
        padding: 24px 0 0;
    }

    .home .home-left {
        width: 100%;
    }

    .home .home-right {
        width: 100%;
    }

    .home .home-right br {
        display: none;
    }

    .w-70.others-exam-body {
        width: 100% !important;
    }

    .other-exams .exam-left {
        margin-right: 0;
        padding-right: 0;
    }

    .subcribe-exam-wrap .recommend-exam .info-recommend-rt .select-btn-wrap {
        width: 100%;
        padding: 0 0 15px;
    }

    .subcribe-exam-wrap .recommend-exam .info-recommend-rt .btn-panel-box {
        padding: 0;
    }

    .ambcardpanel .ambcardpanel-col:not(:last-child) {
        margin-bottom: 16px;
    }

    .ambcardpanel {
        margin: 0 0 24px;
    }

    .tablepagi ul li.nxp-btn a,
    .tablepagi ul li a {
        padding: 6px 8px;
        font-size: 14px;
    }

    .tablepagi ul li {
        margin-right: 5px;
    }

    .contactUs-wrapper .left-div {
        max-width: unset;
    }

    .contactUs-wrapper .right-div {
        max-width: unset;
        display: none;
    }

    .contactUs-sec .contactUs-wrapper {
        column-gap: 32px;
        flex-wrap: wrap-reverse;
        row-gap: 16px;
    }

    .pro-gen-settings.p-20 {
        padding: 0 !important;
    }

    .pro-gen-settings .from-group {
        margin: 0 0 20px;
    }
}

@media screen and (max-width: 960px) {}

@media screen and (max-width: 767px) {
    .sidebar {
        display: none;
    }

    .ant-tabs .password-settings {
        padding: 0;
    }

    .signin-container {
        padding: 15px;
        margin: 20px auto;
    }

    .signin-container br {
        display: none;
    }

    .stepWrap .step-right-side {
        display: none;
    }

    .signin-container-wrap {
        padding: 0 0 1px;
    }

    .header.stickyhead {
        position: relative;

    }

    .stickyheadGap {
        padding: 0 0 60px;
    }

    .header .logo-box-help {
        /* width: 100%; */
        text-align: center;
        padding: 0;
    }

    .header .ant-input-affix-wrapper {
        width: 100% !important;
        order: 3;
        background: #2F327D;
        padding: 10px 20px 20px;
        border-radius: 0px 0px 10px 10px;
        border: 0;
    }

    .header .ant-input-prefix {
        background: #fff;
        margin-inline-end: 0;
        border-radius: 5px 0 0 5px;
        padding: 0 5px;
    }

    .home .home-left {
        border-right: 0;
    }

    .recommend-exam {
        flex-wrap: wrap;
        border: 1px solid #C8CDD6;
        padding: 10px;
        border-radius: 5px;
        background: #ffffff;
    }

    .recommend-exam .recommend-exam-wrap {
        width: 100%;
    }

    .recommend-exam .recommend-exam-btn-wrap {
        padding: 10px;
        width: 100%;

    }

    .recommend-exam .recommend-exam-btn-wrap .buy {
        width: 100%;
        text-align: center;
    }

    .resultWrap {
        margin: 0 auto;
    }

    .jabm-sec {
        padding: 40px 0 0 0;
        min-height: calc(100vh - 62px);
    }

    .resultWrap .btns-panel {
        flex-wrap: wrap;
    }

    .resultWrap .btns-panel .btn {
        width: 100%;
    }

    .resultWrap .btns-panel .btn:first-child {
        margin-bottom: 10px;
    }

    .quSelect-panel-wrap {
        padding: 0;
    }

    .quSelect-panel-wrap .quSelect-panel h3 {
        font-size: 22px;
        margin: 0 0 20px;
        line-height: 1.2;
    }

    .radio-btn-wrap .btn-box label {
        font-size: 16px;
    }

    .quSelect-panel-wrap .quSelect-panel {
        margin: 0 0 20px;
    }

    .qucomm-btn-wrap .btn,
    .qucomm-btn-wrap .btn.big-btn {
        max-width: 210px;
        height: 47px;
        font-size: 14px;
    }

    .qucomm-btn-wrap {
        padding: 24px 0 0;
    }

    .exQucusupload label {
        width: 310px;
    }

    .quit-btn-panel .back-next-side .btn {
        font-size: 14px;
        padding: 8px 8px;
        margin: 0 8px 0px 0;
    }

    .quit-btn-panel .next-subject .btn {
        font-size: 14px;
        padding: 8px 8px;
    }

    .quit-btn-panel .back-next-side .btn img {
        width: 11px;
        margin-left: 2px;
    }

    .exQulistwap h2 {
        font-size: 22px;
        line-height: 1.2;
    }

    .exQulistwap {
        padding: 0 0 30px;
    }

    .resultWrap .btns-panel .btn {
        padding: 12px 78px;
    }

    .blogtham {
        width: 80px;
    }

    .home .home-left {
        width: 100% !important;
        padding: 20px 10px 0;
    }

    .home-right {
        width: 100% !important;
        padding: 10px 10px;
    }

    .exam-lists {
        margin-bottom: 10px;
        row-gap: 12px;
    }

    .header .ant-input-affix-wrapper {
        padding: 10px 8px !important;
        border-radius: 0 !important;
        height: 65px !important;
    }

    .home .home-left .list-heading2 {
        font-size: 20px;
    }

    .home .home-left .exam .exam-name h2 {
        font-size: 16px;
    }

    .pro-gen-settings .submit-actions {
        justify-content: center;
    }

    .pro-gen-settings .submit-actions .save-btn {
        width: 160px;
        height: 40px !important;
    }

    .card-details {
        margin-top: 20px;
    }

    .outlet .contactUs-sec {
        padding: 0 16px 16px;
    }

    .quiListwrap h3 {
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 16px;
    }

    .quiListwrap ul li {
        font-size: 14px;
        line-height: 24px;
    }

    .header.student_dashb_header {
        padding: 4px 16px !important;
    }

    .site-height-wrap.for-student .header .user-info-wrap .tham {
        width: 32px !important;
        height: 32px !important;
    }

    .site-height-wrap.for-student .sidebar .sideitem a {
        padding: 10px 30px;
    }

    .menu-toggle div {
        height: 2.4px;
    }

    .site-height-wrap.for-student .sidebar {
        padding-top: 18px;
        margin-top: 1px;
    }

    .site-height-wrap.for-student .sidebar {
        max-width: 280px !important;
    }

    .home .home-left .welcome-banner {
        background-image: none !important;
    }

    .ambassodorHeader .rt-side .cashOut button {
        font-size: 14px;
        padding: 6px 16px;
        width: unset;
        column-gap: 6px;
    }

    .ambassodorHeader .rt-side .cashOut {
        margin: 0 12px 0 0;
    }

    .ambassodorHeader .rt-side .menu-toggle {
        margin-left: 13px;
    }

    .ambassodorHeader {
        padding: 9px 12px;
    }

    .ambcardpanel .box {
        padding: 10px;
    }

    .ambassodorHeader .rt-side .cashOut button img {
        width: 22px;
    }

    .zenith-box .tham {
        width: 32px;
    }

    .zenith-box .info strong {
        font-size: 24px;
    }

    .zenith-box .info p {
        font-size: 13px;
        line-height: 23px;
    }

    .commtablearea .ttl {
        padding: 10px 8px;
    }
    .commtablearea .tablewrap {
        padding: 0 8px;
    }
    .commtablearea .ttl h3 {
        font-size: 14px;
    }

    .commtablearea .ttl select {
        font-size: 13px;
        padding: 3px 8px;
    }

    .commtablearea .tablewrap table thead th {
        font-size: 13px;
        line-height: 17px;
    }

    .accountbtnbox .btn {
        font-size: 14px;
        line-height: 20px;
        padding: 0 15px;
    }

    .ambmainwrap {
        padding: 16px 12px 0 12px;
    }

    .settings-wrap .ttl h3 {
        font-size: 18px;
    }

    .settings-wrap .settings-row .right-actions .sideBtn .custom-btn {
        width: 103px;
        padding: 0 6px;
        height: 38px;
    }

    .ambmainwrap .settings-wrap {
        padding: 0 !important;
    }

    .settings-wrap .ttl {
        padding: 0 0 12px;
        margin: 0 0 16px;
    }

    .signup_more_link a {
        display: table;
        text-align: center;
        margin: 4px auto 0;
    }
}

@media screen and (max-width: 575px) {
    .otpmodal-wrap .opt-field {
        padding: 3px 5px;
        margin: 0 5px;
        max-width: calc(16.6666667% - 10px);
    }

    .home .home-left .exam-lists .exam-list-col .exam .examinfo .image {
        padding: 14px !important;
    }

    .home-left .exam .image {
        width: 131.822px;
        border: 0px;
    }

    .home .home-left .exam .exambtnpanel {
        width: calc(100% - 131.822px);
    }

    .home-left .exam {
        padding: 20px 20px 20px 18px;

    }

    .image img {
        height: 69px;
    }

    .home .home-left .exam .examinfo .infotext {
        width: calc(100% - 85px);
    }

    .home .home-left .welcome-banner {
        padding: 28px 24px 28px;
        position: relative;
    }

    .home .home-left .welcome-banner p {
        color: #fff !important;
    }

    .recommend-exam .recommend-image {
        width: 85px;
    }

    .recommend-exam .recommend-info {
        width: calc(100% - 85px);
        padding: 0 0 0 8px;
    }

    .home .home-left h3 {
        font-size: 22px;
    }

    .contactUs-wrapper .left-div .ant-card-body h3 {
        font-size: 20px;
        line-height: 28px;
    }

    .contactUs-wrapper .left-div .ant-card-body h5 {
        font-size: 14px;
        line-height: 22px;
    }

    .contactUs-wrapper .left-div .ant-card-body .ant-form .form-actions .ant-btn {
        height: 42px !important;
        font-size: 14px;
    }

    .contactUs-wrapper .left-div .ant-card-body {
        padding: 16px 16px;
    }

    .contactUs-wrapper .left-div .ant-card-body .ant-form .form-actions {
        margin-top: 8px;
    }

    .mobile-menu ul li button img {
        max-width: 21px;
    }

    .recommend-exam .r-exam-name h2 {
        font-size: 16px;
    }

    .header .user-info-wrap.profile-cursor {
        padding: 5px 5px !important;
        height: 52px !important;
    }

    .recommend-exam-lists {
        row-gap: 12px;
    }

    .header .ant-input-affix-wrapper {
        padding: 5px 5px !important;
        height: 52px !important;
    }

    .profile-img {
        width: 25px;
        height: 25px;
        margin-right: 6px;
    }

    :where(.css-dev-only-do-not-override-1me4733).ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
        padding: 10px 0;
    }

    .ant-tabs-tab {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .home .home-left {
        padding: 10px 10px 0;
    }

    .subscribe-btn {
        padding: 8px 12px;
        margin-bottom: 15px;
    }

    .radio-btn-wrap .btn-box label {
        font-size: 14px;
    }

    .radio-btn-wrap {
        column-gap: 10px;
        row-gap: 10px;
    }

    .radio-btn-wrap.pb-5 {
        padding-bottom: 1rem !important;
    }

    .jabm-sec .quTextboxa {
        padding: 0 0 16px;
    }

    .quttl {
        font-size: 28px;
    }

    .qupara {
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 24px;
    }

    .radio-btn-wrap.pb-5~h3 {
        font-size: 20px;
    }

    .home .home-left .list-heading2 {
        font-size: 17px;
        margin-bottom: 13px;
    }

    .settings-wrap .ttl h3 {
        font-size: 14px;
    }

    .settings-wrap .settings-row .right-actions .sideBtn .custom-btn {
        width: 96px;
        padding: 0 4px;
        height: 33px;
        font-size: 13px;
    }
    .ambcardpanel .ambcardpanel-col:not(:last-child) {
        margin-bottom: 10px;
    }

    .ambcardpanel {
        margin: 0 0 16px;
    }
}

@media screen and (max-width: 420px) {
    .home .home-left .exam-lists .exam-list-col .exam .examinfo .image {
        padding: 10px !important;
    }

    .home-left .exam .image {
        width: 124px;
    }

    .home .home-left .exam .exambtnpanel {
        width: calc(100% - 124px);
        padding-left: 14px;
    }

    .home .home-left .exam .exam-name {
        padding-left: 16px;
    }

    .home .home-left .exam .exambtnpanel .start-buy {
        padding: 10px 5px !important;
    }

    .home .home-left .exam .exambtnpanel .start-buy p {
        font-size: 15px !important;
    }

    .home-left .exam {
        row-gap: 14px;
        padding: 16px 16px 16px 14px;
    }
}

@media (max-width: 420px) {
    .accountbtnbox .btn {
        font-size: 13px;
        line-height: 18px;
        padding: 0 10px;
        height: 40px;
    }
}
